<!-- KHUSUS OPERASIONAL -->
<template>
  <Table
    ref-specify="Table"
    :columns="columns"
    :resolve-fetch-response="resolveFetchResponse"
    :resolve-fetch-payload="resolveFetchPayload"
    :there-is-group="false"
    :dateHumanReadAble="dateHumanReadAble"
    enable-create-form="form-kegiatan-operasional-laporan-create"
    enable-view-form="form-kegiatan-operasional-laporan-view"
  />
</template>

<script>
import moment from 'moment'
import Table from './_table.vue'

export default {
  name: 'oprFormKegiatanLaporan',
  components: {
    Table
  },
  data() {
    return {
      columns: [
        {
          label: 'Catatan Kegiatan',
          field: 'jns_kegiatan'
        },
        {
          label: 'Rencana Tanggal Kegiatan',
          field: 'wkt_keg_rencana'
        },
        {
          label: 'Realisasi Kegiatan',
          field: 'realisasi_kegiatan'
        },
        {
          label: 'Details',
          field: 'action'
        }
      ]
    }
  },
  created() {
    // this.$root.$moment.locale('id')
  },
  methods: {
    moment,
    resolveFetchPayload() {
      return {
        API_SAL: 'opr_form_kegiatan_laporan',
        serverParams: {
          sort: {
            type: 'DESC',
            field: 'created_at'
          },
          perPage: 7 // override
        }
      }
    },
    dateHumanReadAble(value) {
      const format = moment(value).format('D MMMM YYYY - HH:mm')
      if (format === 'Invalid date') {
        return '-'
      }
      return format
    },
    resolveFetchResponse(response) {
      return response.data.opr_form_kegiatan_laporans
    }
  }
}
</script>
