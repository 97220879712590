var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "monitoring"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "8",
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('div', {
    staticClass: "d-flex align-items-center"
  })])], 1), _c('b-col', {
    staticClass: "text-right mb-2",
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "custom-search d-flex justify-content-end"
  }, [_vm.acl() ? _c('b-button', {
    staticClass: "button-tambah mr-1",
    attrs: {
      "id": "tambah-data",
      "variant": 'primary',
      "to": {
        name: _vm.enableCreateForm,
        params: {
          previous_link: {
            name: 'form-kegiatan-operasional-laporan'
          }
        }
      }
    }
  }, [_c('span', [_c('strong', [_c('feather-icon', {
    attrs: {
      "icon": "FilePlusIcon"
    }
  }), _vm._v(" Buat Kegiatan Operasional ")], 1)])]) : _vm._e()], 1)])], 1), _vm.yo_isLoading ? _c('div', {
    staticClass: "progress-wrapper"
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" Memuat data… " + _vm._s(_vm.yo_isLoadingValue + '%') + " ")]), _c('b-progress', {
    key: _vm.yo_isLoadingValue,
    class: 'progress-bar-info',
    attrs: {
      "animated": "",
      "value": _vm.yo_isLoadingValue,
      "variant": "info",
      "max": "100"
    }
  })], 1) : _vm._e(), _c('vue-good-table', {
    key: _vm.yoRender,
    ref: "goodTable",
    staticClass: "yo-good-table",
    attrs: {
      "mode": "remote",
      "total-rows": _vm.total_records,
      "columns": _vm.columns,
      "is-loading": _vm.yo_isLoading,
      "rows": _vm.rows,
      "rtl": _vm.direction,
      "search-options": {
        enabled: true,
        skipDiacritics: true,
        placeholder: 'Search this table ( for dateTime, type with format YYYY-MM-DD HH:mm:ss )'
      },
      "select-options": {
        enabled: false,
        selectOnCheckboxOnly: true,
        // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'Rows Selected',
        clearSelectionText: 'Clear',
        disableSelectInfo: false,
        // disable the select info panel on top
        selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      },
      "pagination-options": {
        enabled: true,
        perPage: _vm.serverParams.perPage,
        mode: 'pages',
        position: 'bottom',
        jumpFirstOrLast: true,
        infoFn: function infoFn(params) {
          return "my own page ".concat(params.firstRecordOnPage);
        }
      },
      "group-options": {
        enabled: _vm.thereIsGroup
      },
      "compact-mode": "",
      "theme": "polar-bear",
      "style-class": "vgt-table bordered table-hover",
      "row-style-class": _vm.rowStyleClassFn,
      "line-numbers": true,
      "fixed-header": false,
      "max-height": "700px"
    },
    on: {
      "on-search": _vm.onSearchTable,
      "on-selected-rows-change": _vm.selectionChanged,
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [_c('transition', {
          attrs: {
            "mode": "out-in"
          }
        }, [props.column.field === 'jns_kegiatan' ? _c('span', [_c('feather-icon', {
          staticClass: "mr-1",
          attrs: {
            "size": "25",
            "icon": 'MessageSquareIcon'
          }
        }), _c('b-badge', {
          attrs: {
            "variant": 'light-primary'
          }
        }, [_vm._v(" " + _vm._s(props.row.catatan_kegiatan) + " ")])], 1) : props.column.field === 'wkt_keg_rencana' ? _c('span', [_c('feather-icon', {
          staticClass: "mr-1",
          attrs: {
            "size": "25",
            "icon": 'ClockIcon'
          }
        }), _c('b-badge', {
          attrs: {
            "variant": 'light-primary'
          }
        }, [_vm._v(" " + _vm._s(_vm.dateHumanReadAble(props.row.wkt_keg_rencana)) + " ")]), _c('hr'), _c('small', [_c('i', [_c('strong', [_vm._v("   Created By : " + _vm._s(props.row.user.full_name) + " "), _c('code', [_vm._v("and")]), _vm._v(" Last Updated AT : " + _vm._s(_vm.dateHumanReadAble(props.row.updated_at)) + " ")])])])], 1) : props.column.field === 'realisasi_kegiatan' ? _c('span', [_c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'LayersIcon'
          }
        }), _vm._v(" Jenis Kegiatan ")], 1), _c('b-form-tags', {
          attrs: {
            "value": _vm.perlatanData(props.row.opr_form_kegiatan_laporan_adms),
            "input-id": "tag-kegiatan",
            "input-attrs": {
              'aria-describedby': 'tags-remove-on-delete-help'
            },
            "width": "75",
            "separator": ",",
            "placeholder": "",
            "disabled": true,
            "remove-on-delete": "",
            "no-add-on-enter": ""
          }
        })], 1) : props.column.field === 'action' ? _c('span', [_c('span', [_c('b-button', {
          attrs: {
            "variant": 'outline-primary',
            "block": "",
            "to": {
              name: 'form-kegiatan-operasional-laporan-view',
              params: {
                id: props.row.id,
                api: 'opr_form_kegiatan_laporan',
                previous_link: {
                  name: 'form-kegiatan-laporan-operasional'
                }
              }
            },
            "size": "md"
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "size": "18",
            "icon": 'PenToolIcon'
          }
        }), _vm._v(" Edit ")], 1)], 1), _c('hr'), _c('span', [_c('b-button', {
          attrs: {
            "variant": 'success',
            "block": "",
            "to": {
              name: 'form-kegiatan-operasional-laporan-realisasi',
              params: {
                id: props.row.id,
                api: 'opr_form_kegiatan_adm',
                data_parent: props.row,
                previous_link: {
                  name: 'form-kegiatan-operasional-laporan'
                }
              }
            },
            "size": "md"
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "size": "18",
            "icon": 'ClockIcon'
          }
        }), _vm._v(" Realisasi ")], 1)], 1)]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }